<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="secondary"
    >
    <CCard>
      <CCardHeader>
          <strong>선택하신 {{appLength}}개 앱의 정책을 일괄 변경합니다.</strong>
      </CCardHeader>
      <CCardBody>
                {{appNmStr}}
      </CCardBody>
    </CCard>
    
    <CCard>
      <CCardHeader>
          <strong> 위변조 정책 설정 </strong>
      </CCardHeader>
      <CCardBody>
      <CRow>
          <CCol lg="12">     
                <CRow form class="form-group">
                  <CCol sm="5">
                      앱 위변조 탐지
                  </CCol>
                  <CSwitch
                      color="primary"
                      :checked.sync="appTamperDetectYn"
                      labelOn="ON" 
                      labelOff="OFF"
                      />
              </CRow>
              <CRow form class="form-group">
                  <CCol sm="5">
                      OS 위변조 탐지
                  </CCol>
                  <CSwitch
                      color="primary"                 
                      :checked.sync="osTamperDetectYn"
                      labelOn="ON" 
                      labelOff="OFF"
                      />
              </CRow>
              <CRow form class="form-group">
                  <CCol sm="5">
                      디버깅 탐지
                  </CCol>
                  <CSwitch
                      color="primary"
                      :checked.sync="debugDetectYn"
                      labelOn="ON" 
                      labelOff="OFF"                                 
                      />
              </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
      <template #header>
        <h6 class="modal-title">앱 정책 일괄 변경</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer>
        <form @submit.prevent="formSubmit" method="post">
        <CButton type="submit" size="sm" color="primary" class="float-right" :disabled="false">
            <CIcon name="cil-pencil"/> 
            저장
        </CButton>
        </form>
        <CButton 
        class="btn btn-primary"
         @click="$emit('close-modal')">닫기</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
export default {
  name: 'ModifyAppModal',
  components: {
    
  },
  computed: {
    appLength(){
      return this.appKeyStr.split(",").length;
    }
  },
  data () {
    return {
        isModalOpen:true,
        appTamperDetectYn: true, // 앱위변조탐지 여부
        osTamperDetectYn: true, // OS위변조탐지 여부
        debugDetectYn: true, // 디버깅탐지 여부
    }
  },
  props: {
    appKeyStr: String,
    appNmStr: String,
  },
  methods: {
    async formSubmit() {      
        if(window.confirm(this.appLength+"건의 앱 정책을 일괄 변경하시겠습니까?")) {
          let payload = {
              appTamperDetectYn : this.appTamperDetectYn?"1":"0",
              osTamperDetectYn : this.osTamperDetectYn?"1":"0",
              debugDetectYn : this.debugDetectYn?"1":"0"
          }
          payload.appKeyStr = this.appKeyStr

          try {
              await this.$store.dispatch('appInfo/modifyAppPolicyInfo', payload)
              alert("저장하였습니다.")
              this.$emit('close-modal')
          } catch(error){
              this.$response(error)
              this.loading=false
          } 
        }
    },
  }
}
</script>
